<template>
    <div>
        <div class="modal fade" id="priceModal" tabindex="-1" aria-labelledby="priceModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="priceModalLabel" class="font-weight-bold">{{ModalTitle}}{{PriceCategoryCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CTabs>
                            <CTab title="Price Category" id="tab-1" active>
                                <div class="p-3">
                                    <CRow>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold required">Nama Kategori Harga</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="PriceCategoryName" v-model="PriceCategoryName" class="font-weight-bold" />
                                            <label id="errorPriceCategoryName" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>                                    
                                    </CRow>
                                    <CRow>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold">Tipe Harga</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CSelect id="PriceCategoryType" class="font-weight-bold" :options="PriceCategoryTypeData" :value.sync="PriceCategoryType" />
                                            <label id="errorPriceCategoryType" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>                                    
                                    </CRow>
                                </div>
                            </CTab>
                            <CTab title="Price Category Detail" id="tab-2">
                                <div class="p-3">
                                    <div v-if="this.SaveType !== 'Add'">
                                        <datasource ref="dataSource" :data="this.GridData" :page-size="10" :schema-model-fields="this.SchemaModel"/>  
                                        
                                        <kendo-grid ref="gridDetail"
                                            :data-source-ref="'dataSource'"
                                            :pageable="true"
                                            :editable="true"
                                            :filterable="true"
                                            :sortable="true"
                                            :resizable="true"
                                            >

                                            <kendo-grid-column  :field="'active_flag'"
                                                                :title="'Status'"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                                :attributes="{ style: 'text-align:center;' }"
                                                                :template = "this.status"></kendo-grid-column>

                                            <kendo-grid-column  :field="'product_code'"
                                                                :title="'Kode Item'"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                            <kendo-grid-column  :field="'item_name'"
                                                                :title="'Nama Item'"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                            <kendo-grid-column  :field="'barcode'"
                                                                :title="'Bar Code'"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                            <kendo-grid-column  :field="'type_detail_id'"
                                                                :title="'Type Detail'"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                            <kendo-grid-column  :field="'uom_conv'"
                                                                :title="'Konversi Satuan'"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                            <kendo-grid-column  :field="'price_retail'"
                                                                :title="'Harga Retail'"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;'}"
                                                                :attributes="{ style: 'text-align:right;' }"
                                                                :template= "this.priceRetail"></kendo-grid-column>

                                            <kendo-grid-column  :field="'price_project'"
                                                                :title="'Harga Project'"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                                :attributes="{ style: 'text-align:right;' }"
                                                                :template= "this.priceProject"></kendo-grid-column>
                                        </kendo-grid>

                                    </div>
                                </div>
                            </CTab>
                        </CTabs>
                        <label id="errorPriceGeneral" class="form-error" style="display: none; color: red;"></label>
                        <br>
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import priceServices from '../Script/PriceServices.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'PriceForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle: '',
            PriceCategoryError : 0,

            PriceCategoryCode: null,
            PriceCategoryName: '',

            PriceCategoryType: '',
            PriceCategoryTypeData: [ 
                {value: 'Normal', label: 'Normal'},
                {value: 'Material', label: 'Material'}
            ],
           
            SaveType: '',
            Error: 0,
            gridDetailReload: 0,

            GridData: [],
            SchemaModel: {
                price_category_id: {type: "string", editable: false, validation: {required: true}},
                category_name: { type: "string",editable: false, validation: { required: true}},
                item_id: { type: "string",editable: false, validation: { required: true}},
                item_name: { type: "string",editable: false, validation: { required: true}},
                barcode: { type: "string",editable: false, validation: { required: true}},
                active_flag: { type: "string",editable: false, validation: { required: true}},
                type_detail_id: { type: "string",editable: false, validation: { required: true}},
                uom_conv: { type: "string",editable: false, validation: { required: true}},
                price_retail: { type: "number",editable: true, validation: { min: 0, required: true}},
                price_project: { type: "number",editable: true, validation: { min: 0, required: true}},
            },
        }
    },
    methods: {
        status(e){
            var status = e.active_flag == 'A' ? 'Active': 'New';
            return status;
        },
        priceRetail(e){
            var price = kendo.format('{0:N}', e.price_retail);
            return price;
        },
        priceProject(e){
            var price = kendo.format('{0:N}', e.price_project);
            return price;
        },
        async addClick(){    
            $(".form-error").css("display", "none");        
            this.ModalTitle = 'Add Price Category: ';
            
            this.PriceCategoryCode = null;
            this.PriceCategoryName = '';
            this.PriceCategoryType = this.PriceCategoryTypeData[0].value;
            
            this.SaveType = 'Add';
            this.GridData = '';
            $('#tab-1').trigger('click');
            $('#tab-2').hide();

            window.$('#priceModal').modal('show');
        },
        async editClick(priceData, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Edit Price Category: ';

            this.PriceCategoryCode = priceData.price_category_id;
            this.PriceCategoryName = priceData.price_category_name;
            this.PriceCategoryType = priceData.price_type;
            
            this.SaveType = 'Edit';
            this.GridData = await priceServices.getPriceDetail(this.PriceCategoryCode);

            $('#tab-1').trigger('click');
            $('#tab-2').show();
            this.reloadDetail();
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Price Category: ';
            }

            window.$('#priceModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            this.PriceCategoryError = 0;
            $(".form-error").css("display", "none");

            if(this.PriceCategoryName == ''){
                this.errorShow('errorPriceCategoryName');
                this.PriceCategoryError++;
            }

            if(this.PriceCategoryError != 0){
                this.$swal("Error", "Terdapat error pada price category tab", "error");
            }
        },
        saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const priceData = {
                        price_category_name : this.PriceCategoryName,
                        price_type : this.PriceCategoryType,
                        price_category_detail : []
                    };

                    const variables = {
                        data : priceData
                    }
                    
                    priceServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#priceModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    var priceDetail = [];
                    var detailData = this.$refs.gridDetail.kendoWidget().dataSource._data;
                    for (let i = 0; i < detailData.length; i++) {
                        var detail = 
                        {  
                            item_id: detailData[i].item_id,
                            uom: detailData[i].uom_conv,
                            price_retail: detailData[i].price_retail,
                            price_project: detailData[i].price_project
                        }
                        priceDetail.push(detail)
                    }

                    const priceData = {
                        price_category_name : this.PriceCategoryName,
                        price_type : this.PriceCategoryType,
                        price_category_detail : priceDetail
                    };

                    const variables = {
                        id : this.PriceCategoryCode,
                        data : priceData
                    }
                    
                    priceServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#priceModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        reloadDetail(){
            this.gridDetailReload++;
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>
